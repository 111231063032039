<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-share-variant</v-icon>
          <h1 class="darkGrey--text">Social Media</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Users
            </span>
          </v-tooltip>
        </span>

      </v-col>
    </v-row>

    <v-row>

      <v-col
        cols="4"
        v-for="(item, index) in socialMedia"
        :key="index"
      >
        <v-card
          height="300"
          class="px-6 py-12 d-flex flex-column align-center"
          :elevation="item.status==='connected'?6:item.status==='assigned'?3:1"
          :color="item.status==='disconnected'?'grey lighten-3':'white'"
        >
          <v-img
            max-height="70"
            contain
           :src="require('@/assets/social-' + item.name + '.svg')"
          ></v-img>

          <span class="font-weight-bold text-uppercase my-4">
            {{ item.name }}
          </span>

          <span v-if="item.status==='disconnected'" class="d-flex flex-column">

            <v-btn
              small
              depressed
              color="secondary"
              class="mb-2"
              height="36"
            >
              Connect Account
            </v-btn>

            <v-btn
              text
              class="darkGrey--text text-caption text-uppercase"
              @click="openModal('assign')"
            >
              Assign to another user
            </v-btn>

          </span>

          <span v-if="item.status==='connected'" class="d-flex flex-column">

            <span class="d-flex justify-space-between mb-2">

              <span class="d-flex flex-column mr-4">
                <span class="text-caption">Connected by</span>
                <span class="text-uppercase text-caption font-weight-bold">Charles Simon</span>
              </span>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    depressed
                    color="secondary"
                    height="36"
                    width="36"
                  >
                    <v-icon
                      size="20"
                      v-bind="attrs"
                      v-on="on"
                      color="white"
                    >
                      mdi-open-in-new
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  Go to social media page
                </span>
              </v-tooltip>

            </span>

            <v-btn
              small
              depressed
              color="important"
              class="white--text"
              height="36"
              @click="openModal('disconnect')"
            >
              Disconnect Account
            </v-btn>

          </span>

          <span v-if="item.status==='assigned'" class="d-flex flex-column">

            <span class="d-flex align-center justify-center mb-2">
              <span>
                <v-icon color="important" size="32">mdi-timer-sand</v-icon>
              </span>
              <span class="d-flex flex-column">
                <span class="text-body-2">Assigned to</span>
                <span class="text-body-2">Vee Caron</span>
              </span>
            </span>

            <v-btn
              text
              class="secondary--text text-caption text-uppercase"
              @click="openModal('revoke')"
            >
              Revoke assignment
            </v-btn>
          </span>

        </v-card>
      </v-col>

    </v-row>

    <social-media-assign v-model="modals.assign"></social-media-assign>
    <social-media-disconnect v-model="modals.disconnect"></social-media-disconnect>
    <social-media-revoke v-model="modals.revoke"></social-media-revoke>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import SocialMediaAssign from '@/components/SocialMedia/SocialMediaAssign'
import SocialMediaDisconnect from '@/components/SocialMedia/SocialMediaDisconnect'
import SocialMediaRevoke from '@/components/SocialMedia/SocialMediaRevoke'

export default Vue.extend({
  name: 'SocialMedia',
  components: {
    Portal,
    SocialMediaAssign,
    SocialMediaDisconnect,
    SocialMediaRevoke
  },
  data: () => ({
    modals: {
      assign: false,
      revoke: false,
      disconnect: false
    },
    breadcrumbsItems: [
      {
        text: 'Admin Panel',
        disabled: false,
        href: ''
      },
      {
        text: 'Social Media',
        disabled: true,
        href: ''
      }
    ],
    socialMedia: [
      {
        name: 'facebook',
        status: 'disconnected',
        user: '',
        assigned: ''
      },
      {
        name: 'nextdoor',
        status: 'connected',
        user: 'Charles Simon',
        assigned: ''
      },
      {
        name: 'twitter',
        status: 'assigned',
        user: '',
        assigned: 'Vee Caron'
      },
      {
        name: 'reddit',
        status: 'disconnected',
        user: '',
        assigned: ''
      },
      {
        name: 'instagram',
        status: 'disconnected',
        user: '',
        assigned: ''
      }
    ]
  }),
  methods: {
    openModal (item) {
      this.modals[item] = true
    }
  }
})
</script>
